import React, { useState, useEffect } from 'react';
import { useHistory , Link} from 'react-router-dom';
import AdministrationService from '../services/AdministrationService';

const Login = ({ setIsLoggedIn, setLoggedInUser, setMenus ,setChannelId}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showpassword, setShowpassword] = useState(false);
    const [failureMessage, setFailureMessage] = useState('');
    const history = useHistory();

    const login = async () => { 
        const response = await AdministrationService.loginChannel( username.value, password.value);


        //1 is equal to success
        if (response.responseCode === 1) {
            ///set session storage    
            const sessionData = await AdministrationService.checksession(response);
            setLoggedInUser(sessionData.user); 
            setMenus(sessionData.menus);
            /*setChannelId(sessionData.user.setChannelId)*/
            setIsLoggedIn(true);
            history.push('/Home');
        } else {
            setIsLoggedIn(false);
            setFailureMessage(response.responseMessage); 
        } 
    }
      
    return <>
        <section className="text-black mt-40 my-5 px-500 body-font bg-white login-container border border-gray-300">
            <div className="container px-2 md:py-18 px-2 mx-auto flex flex-wrap items-center">
                <div className="flex justify-center py-2 w-full">
                    <img className="flex justify-center w-42" src="helium-3-logo.png" alt="helium-3-logo" />
                </div>
                <div className="w-full rounded-lg flex flex-col md:ml-auto w-full md:p-2 ">
                    <hr className="py-1 md:py-1 border-gray-300" />
                    <div className="relative">
                        {/*<label htmlFor="full-name" className="leading-7 text-sm text-gray-600">User Name</label>*/}
                        <label className="text-align-start text-sm text-gray-900 md:text-md">Email</label>
                        <input type="email" name="usrname" onChange={(e) => { setUsername({ value: e.target.value }) }}
                            className="pl-2 placeholder:text-gray-400 w-full bg-white rounded border  border-gray-300 focus:border-gray-600 focus:border-b
                            text-base outline-none text-black leading-8 transition-colors duration-200 ease-in-out"  placeholder="Email" />
                    </div>
                    <div className="relative">
                        <label htmlFor="email" className="leading-7 text-sm text-gray-900 md:text-md">Password</label>
                        <input onChange={(e) => { setPassword({ value: e.target.value }) }} type="password" name="psw"
                            className="pl-2 placeholder:text-gray-400 w-full bg-white rounded border  border-gray-300 focus:border-gray-600 focus:border-b
                            text-base outline-none text-black leading-8 transition-colors duration-200 ease-in-out"  placeholder="Password" required />
                    </div> 
                    {/*<Link className="text-sm text-gray-900 hibred-gold hover:text-black space-x-4 hover:text-lg py-2  flex justify-start" to="/Forgotpassword">Forgot password?*/}
                    {/* </Link>*/}
                    <br/>
                    <div className="grid col-span-1 flex space-x-4 md:flex justify-between"> 
                        <button className="text-white bg-black border-0 py-1 px-1 row-span-full md:flex focus:outline-none hover:bg-gray-500 hover:text-white rounded text-lg"
                            onClick={login} disabled={!username.value || !password.value}>Login</button>
                    </div>
                    <div className="p-2 text-center">
                        {(failureMessage !== '') && (
                            <div className="p-2">
                                <div className="text-center bg-red-200 rounded-full p-2 shadow text-teal text-md">
                                    <span className="text-red-700 p-2 w-full text-bold text-lg px-2">{failureMessage}</span>
                                </div>
                            </div>
                        )
                        }

                    </div>
                </div>
            </div>
        </section> 
    </>
}
export default Login;