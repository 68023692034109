import React from 'react'

const NotFoundPage = () => (
    <>
        <h1>404: Page Not Found</h1>
    </>
)

export default NotFoundPage;

