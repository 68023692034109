import React from 'react';

const Pagination = ({ transactionsPerPage, totalTransactions, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalTransactions / transactionsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className='py-2'>
            <div>
                <p className='text-sm text-gray-700'>
                    Showing
                    <span className='font-medium'>
                        {" "}
                        {currentPage * transactionsPerPage - 20}{" "}
                    </span>
                    to
                    <span className='font-medium'> {currentPage * transactionsPerPage} </span>
                    of
                    <span className='font-medium'> {totalTransactions} </span>
                    results
                </p>
            </div>
        <nav className="block">
            <ul className='flex pl-0 rounded list-none flex-wrap'>
                {pageNumbers.map(number => (
                    <li key={number} className='page-item '>
                        <a onClick={() => paginate(number)}  className={
                            currentPage === number
                                ? "bg-blue border-red-300 text-red-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded"
                                : "bg-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center pt-2 px-4 py-2 border text-sm font-medium cursor-pointer rounded"
                        }>
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
            </nav>
            </div>
    );
};

export default Pagination;