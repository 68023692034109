import React, { useState, useEffect, Link } from 'react';
import VersionTwoService from '../services/VersionTwoService';
import VersionOneService from '../services/VersionOneService';
import AdministrationService from '../services/AdministrationService';
import moment from 'moment';

const LifestyleAuditComponent = () => {
    const [searchCriteria, setSearchCriteria] = useState('');
    const [currentItem, setCurrentItem] = useState({});
    const [formMessage, setFormMessage] = useState('');
    const [formState, setFormState] = useState(0);
    const [loading, setLoading] = useState(false);
    const [channelId, setChannelId] = useState();
    const emptyState = {};

    const handleSearchCriteria = async (e) => {
        setFormMessage('')
        setSearchCriteria(e.target.value);
    };
    debugger
    //list search results whenever searchCriteria changes
    useEffect(() => {
        const getChannelId = JSON.parse(sessionStorage.getItem('_WebsiteToken')).user.channelId;
        setChannelId(getChannelId);
    }, []);

    const getData = async () => {
        if (searchCriteria && searchCriteria.length === 13)
        {
            setLoading(true)

            var responseItem = await VersionTwoService.lifestyleAudit(searchCriteria);
           
            if (responseItem.responseCode === 0.2)
            {
                setCurrentItem({ currentItem: emptyState })
                setFormMessage(responseItem.responseMessage)
            }
            else
            {
                setCurrentItem(responseItem);
                setSearchCriteria('');
            }
            setLoading(false)
        }
        else if (searchCriteria && searchCriteria.length > 13)
        {
            setCurrentItem({ currentItem: emptyState })
            setFormMessage('Identity number entered is too long, it must have 13 digits!');
        }
        else if (searchCriteria && searchCriteria.length < 13)
        {
            setCurrentItem({ currentItem: emptyState })
            setFormMessage('Identity number entered is too short, it must have 13 digits!');
        }
        else
        {
            setCurrentItem({ currentItem: emptyState })
            setFormMessage('');
        }
    }


    const loadReport = async () => {
        if (currentItem && currentItem.idNumber) {
            var reportUrl = AdministrationService.getReportUrl();
            reportUrl = reportUrl + 'reportpath=Lifestyle Audit&IDNumber=' + currentItem.idNumber + '&ReportName=LifeStyleAudit' + '&ChannelId=' + channelId;
            window.open(reportUrl);
        }
    }

    return <>
        <section className="bg-grey-200 w-full m-2" id='content'>
            <div className="flex w-full flex-wrap mb-3 h-6 justify-items-start flex-rows-2 md:flex-rows-1 md:flex-cols-2 md:justify-between">
                <div className="flex w-full justify-center md:justify-start md:w-1/2 border-b-1 border-gray-100">
                    <h1 className="text-xl justify-self-center  font-semibold"> Lifestyle Audit  </h1>
                </div>
                <div className="flex w-full justify-center md:justify-end md:w-1/2 border-b-1 border-gray-100">
                    {
                        currentItem?.idNumber &&
                        <p className="hover:text-gray-500 text-black pr-2">
                            <span className="flex inline-block align-middle cursor-pointer" onClick={() => loadReport()} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 " >
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                                </svg>
                                <span className="flex inline-block align-middle "> View Report</span>
                            </span>
                        </p>
                    }

                    {
                        loading &&
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 mt-1 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    }
                    <input placeholder="Search Customer" tabIndex="0" type="number"
                        className="placeholder:text-gray-400 text-black h-8 w-60 pl-5 pr-2 bg-white focus:outline-none appearance-none focus:border-gray-600 rounded border border-gray-300"
                        value={searchCriteria} onChange={(e) => { handleSearchCriteria(e); }} />
                    <button onClick={() => getData()} className="btn inline-block px-2  border border-gray-300 text-white font-medium text-sm leading-tight  rounded bg-black hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-0 transition duration-150 ease-in-out" type="button" id="button-addon3">Search</button>
                </div>

            </div>
            <div className="w-full h-full grid grid-col border border border-gray-300 bg-white rounded mb-4">
                <div className="w-full">
                    <div className="grid md:grid-cols-3 gap-2 px-2">
                        <div className="w-full flex flex-col col-span-3 grid md:grid-cols-3 md:max-w-full gap-2">
                            <div className="relative mb-2">
                                <label htmlFor="idNumber" className="leading-7 text-sm text-black">ID Number</label>
                                <input type="text" placeholder="" name="idNumber" value={currentItem.idNumber ? currentItem.idNumber: " "}
                                    className="pl-3 placeholder:text-blue-800 w-full  rounded border border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-100
 text-base outline-none text-sky-900 leading-8 transition-colors duration-200 ease-in-out" readonly />
                            </div>
                            <div className="relative mb-2">
                                <label htmlFor="name" className="leading-7 text-sm text-black">Name</label>
                                <input value={currentItem.name ? currentItem.name :" "} type="text" placeholder="" name="name"
                                    className="pl-3 placeholder:text-blue-800 w-full  rounded border border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-100
 text-base outline-none text-sky-900 leading-8 transition-colors duration-200 ease-in-out" placeholder=" " readonly />
                            </div>
                            <div className="relative mb-2">
                                <label htmlFor="surname" className="leading-7 text-sm text-black">Surname</label>
                                <input type="text" value={currentItem.surname ? currentItem.surname :" " } name="surname"
                                    className="pl-3 placeholder:text-blue-800 w-full  rounded border border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-100
 text-base outline-none text-sky-900 leading-8 transition-colors duration-200 ease-in-out"  placeholder=" " readonly />
                            </div>

                            <div className="relative mb-2">
                                <label htmlFor="dateOfBirth" className="leading-7 text-sm text-black">Date Of Birth</label>
                                <input type="text" placeholder="" name="dateOfBirth" value={currentItem?.birthDate ? moment(currentItem?.birthDate).format("DD MMMM YYYY"): " " }
                                    className="pl-3 placeholder:text-blue-800 w-full  rounded border border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-100
 text-base outline-none text-sky-900 leading-8 transition-colors duration-200 ease-in-out" readonly />
                            </div>

                       
                                <div className="relative mb-2">
                                <label htmlFor="physicalAddress" className="leading-7 text-sm text-black">Physical Address</label>
                                    <input type="text" placeholder="" name="physicalAddress" value={currentItem?.addressInfo?.address != null ? currentItem?.addressInfo?.address : " "}
                                    className="pl-3 placeholder:text-blue-800 w-full  rounded border border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-100
 text-base outline-none text-sky-900 leading-8 transition-colors duration-200 ease-in-out" required />
                            </div>

                            <div className="relative mb-2">
                                <label htmlFor="isDeceased" className="leading-7 text-sm text-black">Deceased</label>
                                <br />
                                <span className="pl-3 placeholder:text-blue-800 w-full    focus:ring-2 focus:ring-blue-100
 text-base outline-none text-sky-900 leading-8 transition-colors duration-200 ease-in-out">{currentItem?.isDeceased ? 'Yes' : 'No'}</span> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full h-full grid grid-cols-3 gap-2 mb-2">
                <div className="rounded bg-white border border-gray-300 col-span-2">
                    <div class="flex-auto p-4">
                        <div class="flex flex-row -mx-3">
                            <div class="flex-none w-full px-3">

                                <div className="w-full">

                                    <h2 className="text-black">Employment</h2>

                                    <div className="grid grid-cols-3 gap-2 justify-between  bg-gradient-to-tr from-gray-700 to-gray-500 text-white">
                                        <div className="px-2">
                                            <span>Company Name</span>
                                        </div>
                                        <div className="px-2">
                                            <span>Last Updated Date</span>
                                        </div>
                                        <div className="px-2">
                                            <span>First Reported Date</span>
                                        </div>
                                    </div>

                                    <div >
                                        {currentItem?.consumerEmploymentHistory?.map((employment) => {
                                            return (<>
                                                <div className={moment(employment?.lastUpdatedDate).format("DD MMM YYYY") === moment(employment?.firstReportedDate).format("DD MMM YYYY") ? "grid grid-cols-3 gap-2 border-t justify-between" : "grid grid-cols-3 gap-2 border-t justify-between"}>
                                           
                                                    <div className="px-2">
                                                        <span>{employment?.employerDetail}</span>
                                                    </div>

                                                    <div className="px-2">
                                                        <span>{moment(employment?.lastUpdatedDate).format("DD MMM YYYY")}</span>
                                                    </div>
                                                    
                                                    <div className={moment(employment?.lastUpdatedDate).format("DD MMM YYYY") === moment(employment?.firstReportedDate).format("DD MMM YYYY") ? "hidden" :"px-2"}>
                                                        <span>{moment(employment?.firstReportedDate).format("DD MMM YYYY")}</span>
                                                    </div>

                                                </div>
                                            </>)
                                        })}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded bg-white border border-gray-300 col-span-1">
                    <div class="flex-auto p-4">
                        <div class="flex flex-row -mx-3">
                            <div class="flex-none w-full px-3 ">
                                <div className="w-full">
                                    <h2 className="text-black">Affordability</h2>
                                    <div className="grid grid-cols-2 gap-3 justify-between  bg-gradient-to-tr from-gray-700 to-gray-500 text-white">
                                        <div className="px-2">
                                            <span>Description</span>
                                        </div>
                                        <div className="px-2">
                                            <span>Predicted Income</span>
                                        </div>
                                    </div>
                                    <div >
                                        <div className="grid grid-cols-2 gap-3 border-t justify-between">
                                            <div className="px-2">
                                                {currentItem?.consumerAffordability?.displayText}
                                            </div>
                                            <div className="px-2">
                                                <span>{currentItem?.consumerAffordability?.predictedIncome }</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
        <div className="p-2 text-center">
            {(formMessage !== '') && (
                <div className="p-2">
                    <div className="text-center bg-red-200 rounded-full p-2 shadow text-teal text-md">
                        <span className="text-red-700 p-2 w-full text-bold text-lg px-2">{formMessage}</span>
                    </div>
                </div>
            )
            }

        </div>
    </>
}
export default LifestyleAuditComponent;

