import React, { useState, useEffect } from 'react';
import { Route, Switch  } from "react-router-dom";
import { AuthenticatedSessionControl } from 'react-session-control';
import HomeComponent from './components/HomeComponent';
import NavMenuComponent from './components/NavMenuComponent'
import LoginComponent from './components/LoginComponent';
import ReportComponent from './components/ReportComponent'; 
import NotFoundPageComponent from './components/NotFoundPageComponent';
import ChannelsComponent from './components/ChannelsComponent';
import KycComponent from './components/KycComponent';
import FaceViewComponent from './components/FaceViewComponent';
import DhaPhotoComponent from './components/DhaPhotoComponent'; 
import LifestyleAuditComponent from './components/LifestyleAuditComponent'; 
import AdministrationService from './services/AdministrationService';
import axios from "axios";

var now = new Date();
var day = ("0" + now.getDate()).slice(-2);
var month = ("0" + (now.getMonth() + 1)).slice(-2);
const todaysDate = now.getFullYear() + "-" + (month) + "-" + (day);


const today = new Date()
var tomorrow = new Date(today)
var tomorrowsDate = tomorrow.setDate(tomorrow.getDate() + 1)
day = ("0" + tomorrow.getDate()).slice(-2);
var month = ("0" + (tomorrow.getMonth() + 1)).slice(-2);
const tomorrows_Date = tomorrow.getFullYear() + "-" + (month) + "-" + (day);


function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState({});
    const [lookupTypes, setLookupTypes] = useState({});
    const [menus, setMenus] = useState([]);
    const [channelId, setChannelId] = useState();



    axios.defaults.baseURL = 'https://localhost:44303/'; //process.env.REACT_APP_APIENDPOINT;
    axios.defaults.headers.post["Content-Type"] = "application/json";

    // load initial data or redirect to login
    useEffect(() => {
        const loadData = async () => {
            const sessionData = await AdministrationService.checksession();
            if (sessionData) {
                setLoggedInUser(sessionData.user);
                setMenus(sessionData.menus);
                setChannelId(sessionData.user.channelId);
                setIsLoggedIn(true);
            } 
        }
        loadData();
    }, []);



 

    function handleSessionControlLogout(logoutType) {
        AdministrationService.logout();
        setIsLoggedIn(false);
        window.location.href = '/';
    };

    return (
        <>  
            <NavMenuComponent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} menus={menus} setMenus={setMenus}/>
            <div className="mx-5"> 
                <Switch>
                    <Route path="/" exact component={() => <LoginComponent setIsLoggedIn={setIsLoggedIn} setLoggedInUser={setLoggedInUser} setLookupTypes={setLookupTypes} setMenus={setMenus}/>} />
                    <Route path="/Login" exact component={() => <LoginComponent setIsLoggedIn={setIsLoggedIn} setLoggedInUser={setLoggedInUser} setLookupTypes={setLookupTypes} setMenus={setMenus} setChannelId={setChannelId} />} />
                    <Route path="/Home" component={() => <HomeComponent todaysDate={todaysDate} channelId={channelId} /* isHome={isHome} setIsHome={setIsHome}*/ />} />
                    <Route path="/Transactions" component={() => <ReportComponent todaysDate={todaysDate} channelId={channelId} tomorrows_Date={tomorrows_Date}/* isHome={isHome} setIsHome={setIsHome} *//>} />
                    <Route path="/Channel" component={ChannelsComponent} />
                    <Route path="/Kyc" component={KycComponent}  />
                    <Route path="/FaceView" component={FaceViewComponent} />
                    <Route path="/Dha Photo" component={DhaPhotoComponent} />
                    <Route path="/Lifestyle Audit" component={LifestyleAuditComponent} />   
                    <Route component={NotFoundPageComponent} /> 
                </Switch> 
            </div>
        </>
    );
} 
export default App;
