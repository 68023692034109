import React, { useState, useEffect, useRef } from 'react';
import AdministrationService from '../../services/AdministrationService';
import * as d3 from 'd3';


const DashboardChartComponent = ({ periodEndDate, chartPeriod, chartInterval, chartWidth, filterChannelId, triggerRefresh}) => {
    const chartRefs = useRef([React.createRef()]);
    const [intChartInterval, setIntChartInterval] = useState(chartInterval);

    const [dataArray, setDataArray] = useState([]);
  

    useEffect(() => {
        let channelId = JSON.parse(sessionStorage.getItem('_WebsiteToken')).user.channelId;
        getData(filterChannelId === 0 ? channelId : channelId = filterChannelId, periodEndDate);
    }, [intChartInterval, periodEndDate, filterChannelId, triggerRefresh]);


    const getData = async (channelId, filterDate ) => {
        var rawData = await AdministrationService.getUsageChart(
            {
                'chartGroup': 'Daily',
                'periodEndDate': periodEndDate,
                'chartInterval': intChartInterval,
                'chartPeriod': chartPeriod
            }, channelId, periodEndDate
        );
        setDataArray(rawData);
        await renderChart(rawData, chartWidth);
    }
    const renderChart = async (rawData, chartWidth) => {

        let maxYValue = 0;
        let canvasWidth = chartWidth;
        let canvasHeight = 250;

        //lets arrange our data in a more D3 friendly format
        let data = rawData.map((d) => {
            if (d.currentValue > maxYValue) maxYValue = d.currentValue;
            if (d.previousValue > maxYValue) maxYValue = d.previousValue;

            if (d.currentValue != 0 || d.previousValue != 0)
            {
                return {
                    'currentValue': d.currentValue,
                    'previousValue': d.previousValue,
                    'description': d.description,
                };
            }
            else
            {
                return {
                    'currentValue': null,
                    'previousValue': null,
                    'description': d.description,
                };
            }
        });

        // set the dimensions and margins of the graph
        const margin = { top: 10, right: 30, bottom: 20, left: 40 },
        width = canvasWidth - margin.left - margin.right,
        height = canvasHeight - margin.top - margin.bottom;
      
            

        //// append the svg object to the body of the page
        //d3.select("svg").remove();
        //const svg = d3.select(chartRefs.current[0].current)
        // append the svg object to the body of the page
        d3.select(chartRefs.current[0].current).select("svg").remove();
        const svg = d3.select(chartRefs.current[0].current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // the chart has only 2 groups of interest
        const subgroups = ['currentValue', 'previousValue'];

        // this grouping can be days of week, hour number, week number, month number
        const groups = data.map(d => d.description)

        // Add X axis
        const x = d3.scaleBand()
            .domain(groups)
            .range([0, width])
            .padding([0.2]);

        svg.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(d3.axisBottom(x).tickSize(0));

        // Add Y axis
        const y = d3.scaleLinear()
            .domain([0, maxYValue])
            .range([height, 0]);

        svg.append("g")
            .call(d3.axisLeft(y));

        // Another scale for subgroup position
        const xSubgroup = d3.scaleBand()
            .domain(subgroups)
            .range([0, x.bandwidth()])
            .padding([0.05])

        // color palette = one color per subgroup
        const color = d3.scaleOrdinal()
            .domain(subgroups)
            .range(['#2b3880', '#475bcc'])


        // Show the bars
        svg.append("g")
            .selectAll("g")
            // Enter in data = loop group per group
            .data(data)
            .join("g")
            .attr("transform", d => `translate(${x(d.description)}, 0)`)
            .selectAll("rect")
            .data(function (d) { return subgroups.map(function (key) { return { key: key, value: d[key] }; }); })
            .join("rect")
            .attr("x", d => xSubgroup(d.key))
            .attr("y", d => y(d.value))
            .attr("width", xSubgroup.bandwidth())
            .attr("height", d => height - y(d.value))
            .attr("fill", d => color(d.key))
        //.on("mouseover", mouseover)
        //.on("mousemove", mousemove)
        //.on("mouseleave", mouseleave);
        svg.append("circle").attr("cx", 150).attr("cy", -5).attr("r", 4).style("fill", "#2b3880")
        svg.append("circle").attr("cx", 250).attr("cy", -5).attr("r", 4).style("fill", "#475bcc")
        svg.append("text").attr("x", 160).attr("y", -5).text("Current").style("font-size", "11px").attr("alignment-baseline", "middle")
        svg.append("text").attr("x", 260).attr("y", -5).text("Previous").style("font-size", "11px").attr("alignment-baseline", "middle")
    };


    return <>
        <div className="w-full h-full grid grid-col mb-4">
            <div className="rounded bg-white border  border-gray-300">
                <div class="flex-auto p-4">
                    <div class="flex flex-row -mx-3">
                        <div class="flex-none w-2/3 max-w-full px-3">
                            <div>
                                <p class="mb-0 font-sans font-semibold leading-normal text-size-sm">Usage Chart</p>
                                <hr class="border-b-1 border-grey-300" />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row -mx-3">
                        <div className="bg-white rounded">
                            <div className="flex flex-wrap">
                                <div className="px-8 py-2 ml-24 md:ml-0 w-full">
                                    {/*<input type="date" value={intChartInterval} onChange={(e) => { setIntChartInterval(e.target.value); }} />*/}
                                    <select  value={intChartInterval} onChange={(e) => { setIntChartInterval(e.target.value); }}
                                        className="bg-white rounded border border-gray-300 focus:border-grey-900 focus:ring-2
                                        focus:ring-green-100 text-base outline-none text-gray-700 transition-colors duration-200 ease-in-out" required >
                                        <option value="Daily">Daily</option>
                                        <option value="Hourly">Hourly</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                    </select>
                                </div>
                                <div className="px-8 py-2 ml-24 md:ml-0 w-full">
                                    <div ref={chartRefs.current[0]} className="overflow-x-auto w-11/12 md:pl-4"> </div>
                                </div> 
                            </div>
                        </div >
                    </div>
                </div>
            </div>
        </div>
        
    </>
}
export default DashboardChartComponent;