import React, { useState, useEffect, useRef } from 'react'; 
import * as d3 from 'd3';
import AdministrationService from '../../services/AdministrationService';
import * as moment from 'moment';


const DashboardListComponent = ({ periodEndDate, chartPeriod, chartInterval, filterChannelId, triggerRefresh }) => {
    const chartRefs = useRef([React.createRef()])

    const [dataArray, setDataArray] = useState([]);
    const [topfive, setTopFive] = useState([]);
    const [recent, setRecent] = useState([]);

 

    useEffect(() => {
        let channelId = JSON.parse(sessionStorage.getItem('_WebsiteToken')).user.channelId;

        getData(filterChannelId === 0 ? channelId : channelId = filterChannelId, periodEndDate);
        getTopFive(filterChannelId === 0 ? channelId : channelId = filterChannelId, periodEndDate);
        getRecent(channelId);

    }, [periodEndDate, filterChannelId, triggerRefresh]);

    const getRecent = async (channelId) => {
        var response = await AdministrationService.getRecent(channelId);
        setRecent(response.data);
    }
    const getTopFive = async (channelId, periodEndDate) => {
        var response = await AdministrationService.getTop5(channelId, periodEndDate);
        setTopFive(response.data);

    }
    const getData = async (channelId, periodEndDate) => {
        var response = await AdministrationService.getMethodUsageStats(channelId, periodEndDate);
        setDataArray(response.data);
    }



    return <>

        <div className="w-full h-full grid grid-cols-3 gap-4 mb-4">
            <div className="rounded bg-white border border-gray-300">
                <div class="flex-auto p-4">
                    <div class="flex flex-row -mx-3">
                        <div class="flex-none w-2/3 max-w-full px-3">
                            <div>
                                <p class="mb-0 font-sans font-semibold leading-normal text-size-sm">Recently viewed</p>
                                <hr class="border-b-1 border-grey-300" />
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row -mx-3">
                        <div class="w-full px-3">
                            <div>
                                <table class="table-auto w-full border-b  border-grey-300">
                                    <thead class="text-white bg-gradient-to-r from-blue-500 to-blue-900">
                                        <tr><td class="p-1"> Id Number</td><td class="p-1">Date Time </td><td class="p-1">Name </td> </tr>
                                    </thead>
                                    <tbody>
                                       
                                        {
                                            recent !=null ?
                                            recent?.map((recent) =>
                                            {
                                            return <>
                                                <tr class="bg-white border-b">
                                                <td class="p-1">{recent.idNumber} </td>
                                                    <td class="p-1">{moment(recent.dateAccessed).format("DD MMM YYYY")} </td>
                                                <td class="p-1">{recent.userName} </td>
                                                </tr>
                                            </>
                                            }) : "No data found"
                                        }
                                        
                                        {/*<tr class="bg-gray-100 border-b"><td class="p-1">1212123333567</td><td class="p-1">2022-07-27 12:30 </td><td class="p-1">John Doe </td> </tr>*/}
                                        {/*<tr class="bg-white border-b"><td class="p-1">1212123333567 </td><td class="p-1">2022-07-27 12:30 </td><td class="p-1">John Doe </td> </tr>*/}
                                        {/*<tr class="bg-gray-100 border-b"><td class="p-1">1212123333567 </td><td class="p-1">2022-07-27 12:30 </td><td class="p-1">John Doe </td> </tr>*/}
                                        {/*<tr class="bg-white border-b"><td class="p-1">1212123333567 </td><td class="p-1">2022-07-27 12:30 </td><td class="p-1">John Doe </td> </tr>*/}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rounded bg-white border border-gray-300">
                <div class="flex-auto p-4">
                    <div class="flex flex-row -mx-3">
                        <div class="flex-none w-2/3 max-w-full px-3">
                            <div>
                                <p class="mb-0 font-sans font-semibold leading-normal text-size-sm">Top 5 Highest Queries</p>
                                <hr class="border-b-1 border-grey-300" />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row -mx-3">
                        <div class="w-full px-3">
                            <div>
                                <table class="table-auto w-full border-b  border-grey-300">
                                    <thead class="bg-gray-800 text-white">
                                        <tr><td class="p-1">Id Number </td>
                                            <td class="p-1">Queries </td></tr>
                                    </thead>
                                    <tbody>
                                        {topfive?.map((data) => {
                                            return <>
                                                <tr class="bg-white border-b">
                                                <td class="p-1">{data.idNumber} </td>
                                                <td class="p-1">{data.queries} </td>
                                            </tr> </>
                                        })}
                                       
                                        {/*<tr class="bg-gray-100 border-b"><td class="p-1">1212123333567</td><td class="p-1">2022-07-27 12:30 </td><td class="p-1">12 </td><td class="p-1">+5% </td></tr>*/}
                                        {/*<tr class="bg-white border-b"><td class="p-1">1212123333567 </td><td class="p-1">2022-07-27 12:30 </td><td class="p-1">7 </td><td class="p-1">+5% </td></tr>*/}
                                        {/*<tr class="bg-gray-100 border-b"><td class="p-1">1212123333567 </td><td class="p-1">2022-07-27 12:30 </td><td class="p-1">6 </td><td class="p-1"> +5%</td></tr>*/}
                                        {/*<tr class="bg-white border-b"><td class="p-1">1212123333567 </td><td class="p-1">2022-07-27 12:30 </td><td class="p-1">5 </td><td class="p-1">+5% </td></tr>*/}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rounded bg-white border border-gray-300">
                <div class="flex-auto p-4">
                    <div class="flex flex-row -mx-3">
                        <div class="flex-none w-2/3 max-w-full px-3">
                            <div>
                                <p class="mb-0 font-sans font-semibold leading-normal text-size-sm">Usage Stats</p>
                                <hr class="border-b-1 border-grey-300" />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row -mx-3">
                        <div class="w-full px-3">
                            <div>
                                <table class="table-auto w-full border-b  border-grey-300">
                                    <thead class="text-white bg-gradient-to-r from-red-500 to-red-900">
                                        <tr><td class="p-1">Function </td><td class="p-1">Total</td> </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {dataArray?.map((data) => {
                                            return <> <tr class="bg-white border-b"><td class="p-1">{data.apiMethodDescription} </td><td class="p-1">{data.count} </td> </tr> </>
                                        })}
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default DashboardListComponent;