import React, { useState, useEffect }from 'react'
import { render } from 'react-dom'
import { Link } from 'react-router-dom'
import AdministrationService from '../services/AdministrationService'
import VersionOneService from '../services/VersionOneService'
import moment from 'moment';
import Pagination from './shared/PaginationComponent'



const ReportComponent = ({ todaysDate, tomorrows_Date/*, isHome, setIsHome*/}) => {

    
    const [searchCriteria, setSearchCriteria] = useState('');
    const [channelId, setChannelId] = useState();
    const [periodStartDate, setPeriodStartDate] = useState(todaysDate);
    const [periodEndDate, setPeriodEndDate] = useState(tomorrows_Date);

    //pagination stuff
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [transactionsPerPage] = useState(20);
    const [currentList, setCurrentList] = useState([]);

    const [filterChannelId, setFilterChannelId] = useState(0);


    const handleSearchCriteria = async (e) => {
        setSearchCriteria(e.target.value);
   
    };

    useEffect(() => {
    
        const getChannelId = JSON.parse(sessionStorage.getItem('_WebsiteToken')).user.channelId;
        setChannelId(getChannelId);

        getTransactions(filterChannelId === 0 ? channelId : filterChannelId, periodStartDate, periodEndDate);
        getListData(0);
    }, [channelId,filterChannelId, periodStartDate, periodEndDate]);


    const handleChange = async (e) => {
        setFilterChannelId(e.target.value);
    };

    const getListData = async (channelId) => {
        var responseItem = await AdministrationService.getChannel(channelId);
        setCurrentList(responseItem);
    };

    // Get current transactions
    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const currentTransactions = transactions?.slice(indexOfFirstTransaction, indexOfLastTransaction);

 
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    debugger
    const getTransactions = async (channelId, periodStartDate, periodEndDate ) => {
        var response = await VersionOneService.getTransactions(channelId, periodStartDate, periodEndDate);
        setTransactions(response?.data?.records);
    }
    //for reports
    var id = filterChannelId === 0 ? channelId : filterChannelId;

    const loadReport = async () => { 
        var reportUrl = AdministrationService.getReportUrl();

        if (searchCriteria && searchCriteria.length === 13 && periodStartDate !== null || periodStartDate !== '' && periodEndDate !== null || periodEndDate !== '' )
        {
            reportUrl = reportUrl + 'reportpath=TransactionsReport&ChannelId=' + id + '&StartDate=' + periodStartDate + '&EndDate='+ periodEndDate +'&ReportName=Transactions'+ '&IDNumber=' + searchCriteria.toString();
        }// need to remove these checks
        else if (searchCriteria && searchCriteria.length === 13)
        {
            reportUrl = reportUrl + 'reportpath=TransactionsReport&ChannelId=' + id + '&ReportName=Transactions' + '&StartDate=&EndDate=' + '&IDNumber=' + searchCriteria.toString();
        }
        else
        {
            reportUrl = reportUrl + 'reportpath=TransactionsReport&ChannelId=' + id + '&ReportName=Transactions' + '&StartDate=&EndDate=' + '&IDNumber=';
        }
        window.open(reportUrl);
    }

    return <>
        <section className="bg-grey-200 w-full m-2" id='content'>
            <div className="flex w-full flex-wrap mb-3 h-6 justify-items-start flex-rows-2 md:flex-rows-1 md:flex-cols-2 md:justify-between">
                <div className="flex w-full justify-center md:justify-start md:w-1/2 border-b-1 border-gray-100">
                    <h1 className=" text-xl  justify-self-center  font-semibold"> Transactions  </h1>


                    <div className="flex absolute right-3">
                        <div className="flex w-full justify-center md:justify-center ">
                                {
                                <p className="hover:text-gray-500 text-black pr-2">
                                        <span className="flex inline-block align-middle cursor-pointer" onClick={() => loadReport()} >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 " >
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                                            </svg>
                                            <span className="flex inline-block align-middle "> View Report</span>
                                        </span>
                                    </p>
                                }

                            <input placeholder="Search Customer" tabIndex="0" type="number" className="placeholder:text-gray-400 text-black h-8 w-60 pl-5 pr-2 bg-white focus:outline-none appearance-none focus:border-gray-600 rounded border border-gray-300"  value={searchCriteria} onChange={(e) => { handleSearchCriteria(e); }} />

                            <div className="flex relative mb-2">
                                <span class="text-sm border border-gray-300 rounded-l ml-2 px-4 bg-600 py-1 h-8 whitespace-no-wrap">Start:</span>
                                <input className=" text-gray-600 h-8 w-60 pl-5 pr-2 bg-white focus:outline-none appearance-none focus:border-gray-600 rounded-r border border-gray-300" type="date" value={periodStartDate} onChange={(e) => { setPeriodStartDate(e.target.value); }} />
                            </div>
                            <div className="flex relative mb-2">
                                <span class="text-sm border border-gray-300 rounded-l ml-2 px-4 bg-600 py-1 h-8 whitespace-no-wrap">End:</span>
                            <input className=" text-gray-600 h-8 w-60 pl-5 pr-2 bg-white focus:outline-none appearance-none focus:border-gray-600 rounded-r border border-gray-300" type="date" value={periodEndDate} onChange={(e) => { setPeriodEndDate(e.target.value); }} />
                            </div>




                    {channelId === 1 &&
                        <div className="">
                            <div className="relative inline-flex self-center">
                                <svg className="text-white font-bold bg-black absolute top-0 right-0 m-1 pointer-events-none p-2 rounded" xmlns="http://www.w3.org/2000/svg" width="30px" height="25px" viewBox="0 0 38 22" version="1.1">
                                    <title>F09B337F-81F6-41AC-8924-EC55BA135736</title>
                                    <g id="ZahnhelferDE�Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="ZahnhelferDE�Icon&amp;Asset-Download" transform="translate(-539.000000, -199.000000)" fill="#ffffff" fill-rule="nonzero">
                                            <g id="Icon-/-ArrowRight-Copy-2" transform="translate(538.000000, 183.521208)">
                                                <polygon id="Path-Copy" transform="translate(20.000000, 18.384776) rotate(135.000000) translate(-20.000000, -18.384776) " points="33 5.38477631 33 31.3847763 29 31.3847763 28.999 9.38379168 7 9.38477631 7 5.38477631" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>

                                    <select onChange={(e) => { handleChange(e); }} className="text-black w-full  rounded border border-gray-300 focus:border-gray-600 text-gray-600 h-8 w-60 pl-5 pr-10 bg-white focus:outline-none appearance-none">
                                 { currentList &&
                                        currentList.map((channel, i) => (<>
                                            <option key={i + channel.channelName} value={channel.channelId}> {channel.channelName}</option>
                                        </>))
                                  }
                                </select>
                            
                            </div>
                        </div>
                    }
                        </div>
                    </div>
                </div>
              
            </div>
            <div className="w-full h-full grid grid-col border border-1 border-gray-300 bg-white rounded">
                <div class="flex-auto p-4">
                    <div class="flex flex-row -mx-3">
                        <div class="flex-none w-full px-3">
                            <div className="w-full"> 
                                <div className="grid grid-cols-8 gap-3 justify-between bg-gradient-to-tr from-gray-700 to-gray-500 text-white">
                                    <div className="px-2 ">
                                        <span>Foreign Reference</span>
                                    </div>
                                    <div className="px-2 ">
                                        <span>Reference Number</span>
                                    </div>
                                   
                                    <div className="px-2">
                                        <span>ID Number</span>
                                    </div>
                                    <div className="px-2">
                                        <span>Username</span>
                                    </div>
                                    <div className="px-2">
                                        <span>API Method</span>
                                    </div>
                                    <div className="px-2">
                                        <span>Result Code</span>
                                    </div>
                                    <div className="px-2">
                                        <span>Channel</span>
                                    </div>
                                    <div className="px-2">
                                        <span>Time</span>
                                    </div>
                                </div>
                                {currentTransactions?.filter((transaction) => !searchCriteria.length ||
                                    transaction.resultSource.toLowerCase().includes(searchCriteria.toString().toLocaleLowerCase()) ||
                                     transaction.idNumber.toString().toLowerCase().includes(searchCriteria.toString().toLowerCase())).map((item) =>
                                    {
                                    return <>
                                      <div className=" grid grid-cols-8 gap-3 justify-between border-b">
                                        <div className="px-2">
                                                <span>{item.foreignReference}</span>
                                        </div>
                                        <div className="px-2 text-sm">
                                                <span>{item.referenceNumber}</span>
                                        </div>
                                        <div className="px-2">
                                                <span>{item.idNumber}</span>
                                        </div>
                                        <div className="px-2">
                                                <span>{item.userName}</span>
                                        </div>
                                        <div className="px-2">
                                                <span>{item.apiMethod}</span>
                                        </div>
                                        <div className="px-2">
                                                <span>{item.resultCode}</span>
                                        </div>
                                        <div className="px-2">
                                                <span>{item.channel}</span>
                                        </div>
                                        <div className="px-2">
                                                <span>{moment(item.timestamp).format("DD MMM YYYY, h:mm:ss a")}</span>
                                        </div>
                                     </div>
                                         </>
                                     })}
                                   <Pagination
                                    transactionsPerPage={transactionsPerPage}
                                    totalTransactions={transactions?.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
        
    </>
}
export default ReportComponent;


    
