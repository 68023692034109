import React, { useState, useRef, useEffect} from 'react'
import DashboardStatusComponent from './shared/DashboardStatusComponent'
import DashboardChartComponent from './shared/DashboardChartComponent'
import DashboardListComponent from './shared/DashboardListComponent'
import AdministrationService from '../services/AdministrationService';

const Home = ({ todaysDate/*, isHome, setIsHome */}) => {

    const contentRefs = useRef([React.createRef()]);
    const [periodEndDate, setPeriodEndDate] = useState(todaysDate);
    const [chartInterval, setChartInterval] = useState('Daily');
    const [chartPeriod, setChartPeriod] = useState(0);
    const [currentList, setCurrentList] = useState([]);
    const [filterChannelId, setFilterChannelId] = useState(0);
    const [channelId, setChannelId] = useState();
    const [triggerRefresh, setTriggerRefresh] = useState(false);

    useEffect(() => {
        const getchannelId = JSON.parse(sessionStorage.getItem('_WebsiteToken')).user.channelId; let channelId = JSON.parse(sessionStorage.getItem('_WebsiteToken')).user.channelId;
        setChannelId(getchannelId);
        getListData(0);
    }, []);
     
    const handleChange = async (e) => {
        setFilterChannelId(e.target.value);
    };

    const getListData = async (channelId) => {
        var responseItem = await AdministrationService.getChannel(channelId);
        setCurrentList(responseItem);
    };

    function refresh() {
        var _triggerRefresh = !triggerRefresh;
        setTriggerRefresh(_triggerRefresh);
    }
    setTimeout(refresh, 30000);


    return <>
        <section className="bg-grey-200 w-full m-2 mb-4" id='content'>
           
            <div className=" flex w-full flex-wrap mb-3 h-6 justify-items-start flex-rows-2 md:flex-rows-1 md:flex-cols-2 md:justify-between">
                <div className=" flex w-full justify-center md:justify-start md:w-1/2 border-b-1 border-gray-100">
                    <h1 className="text-xl justify-self-center font-semibold">Home</h1>

                    <div className="absolute right-3">
                        <div className=" flex w-full justify-center md:justify-center">
                    <input className=" text-gray-600 h-8 w-60 pl-5 pr-2 bg-white focus:outline-none appearance-none focus:border-gray-600 rounded border border-gray-300" type="date" value={periodEndDate} onChange={(e) => { setPeriodEndDate(e.target.value); }} />

                        {channelId === 1 &&
                        <div className="right-3">
                            <div className="relative inline-flex self-center">
                                <svg className="text-white font-bold bg-black absolute top-0 right-0 m-1 pointer-events-none p-2 rounded" xmlns="http://www.w3.org/2000/svg" width="30px" height="25px" viewBox="0 0 38 22" version="1.1">
                                    <title>F09B337F-81F6-41AC-8924-EC55BA135736</title>
                                    <g id="ZahnhelferDE—Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="ZahnhelferDE–Icon&amp;Asset-Download" transform="translate(-539.000000, -199.000000)" fill="#ffffff" fill-rule="nonzero">
                                            <g id="Icon-/-ArrowRight-Copy-2" transform="translate(538.000000, 183.521208)">
                                                <polygon id="Path-Copy" transform="translate(20.000000, 18.384776) rotate(135.000000) translate(-20.000000, -18.384776) " points="33 5.38477631 33 31.3847763 29 31.3847763 28.999 9.38379168 7 9.38477631 7 5.38477631" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            <select onChange={(e) => { handleChange(e); }} className="text-black w-full  rounded border border-gray-300 focus:border-gray-600 text-gray-600 h-8 w-60 pl-5 pr-10 bg-white focus:outline-none appearance-none">
                                        { currentList &&
                                            currentList.map((channel, i) => (<>
                                                <option key={i} value={channel.channelId}> {channel.channelName}</option>
                                            </>))
                                        }
                                    </select>
                            </div>
                        </div>
                        }
                    
                     </div>                               
                    </div>
                </div>
            </div>

            <DashboardStatusComponent periodEndDate={periodEndDate} filterChannelId={filterChannelId} channelId={channelId} triggerRefresh={triggerRefresh}/>
            <DashboardChartComponent periodEndDate={periodEndDate} chartPeriod={chartPeriod} chartInterval={chartInterval} chartWidth={900} filterChannelId={filterChannelId} triggerRefresh={triggerRefresh}/>
            <DashboardListComponent periodEndDate={periodEndDate} filterChannelId={filterChannelId} triggerRefresh={triggerRefresh} />
            
        </section> 
    </>
}

export default Home;



