import axios from 'axios'
import AdministrationService from './AdministrationService'


class VersionTwoService {
    homeAffairsIDVerification = async (idNumber) => {
        try {
            var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.post('api/V2/HomeAffairsIDVerification', payload);
            return response.data;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };

    employmentVerification = async (idNumber) => {
        try {
            var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.post('api/V2/EmploymentVerification', payload);
            return response.data;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };


    lifestyleAudit = async (idNumber) => {
        try {
            var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.post('api/V2/LifestyleAudit', payload);
            return response.data;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };


    incomePredictor = async (idNumber) => {
        try {
            var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.post('api/V2/IncomePredictor', payload);
            return response.data;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };

    dhaKycLookupWithPhoto = async (idNumber) => {
        try {
            var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.post('api/V2/DHAKycLookupWithPhoto', payload);
            return response.data;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];

        }
    }; 
}

export default new VersionTwoService();