import axios from 'axios'
import AdministrationService from './AdministrationService'

class VersionOneService {
    identityLookup = async (idNumber) => {
        try {


            var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.post('api/V1/IdentityLookup', payload);
            return response.data;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];

        }
    };

    addressLookup = async (idNumber) => {
        try {
            var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.post('api/V1/AddressLookup', payload);
            return response.data;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };

    contactLookup = async (idNumber) => {
        try {
            var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.post('api/V1/ContactLookup', payload);
            return response.data;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };

    kycLookup = async (idNumber) => {
        try {
            var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.post('api/V1/KycLookup', payload);
            var responseItem = response.data;

            if (responseItem.responseCode === 1)
            {
                var returnedObj = { ...responseItem.identityInfo };
                if (responseItem.contactInfo != null) {
                    responseItem.contactInfo.forEach((item) => {
                        if (item.contactType == 1) returnedObj.mobile = item.contact;
                        if (item.contactType == 2) returnedObj.landline = item.contact;
                        if (item.contactType == 3) returnedObj.email = item.contact;
                    });
                }

                returnedObj.physicalAddress = responseItem.addressInfo.address;
                returnedObj.suburb = responseItem.addressInfo.suburb;
                returnedObj.city = responseItem.addressInfo.city;
                returnedObj.province = responseItem.addressInfo.province;
                returnedObj.postalCode = responseItem.addressInfo.postalCode;
                returnedObj.postalAddress = responseItem.addressInfo.address;
                returnedObj.maritalStatus = responseItem.maritalStatus;
                return returnedObj;
            }

            //responseItem.addressInfo.forEach((item) => {
            //    returnedObj.physicalAddress = item.address;
            //    returnedObj.postalAddress = item.address; 
            //});
            return responseItem
            
        }
        catch (err) {
            console.log(err);
        }
    }; 


    getTransactions = async (channelId, startDate, endDate, idNumber) => {
        try {
            var payload = AdministrationService.addCredentials(idNumber);

            const response = await axios.post(`api/V1/TransactionReport?channelId=${channelId}&startDate=${startDate}&endDate=${endDate}`, payload);
            return response;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };





}

export default new VersionOneService();