import React, { useState, useEffect, useContext } from 'react'; 
import AdministrationService from '../services/AdministrationService';
import { Link } from 'react-router-dom'
import moment from 'moment';
import VersionTwoService from '../services/VersionTwoService';

const FaceViewComponent = () => {
    const [searchCriteria, setSearchCriteria] = useState('');
    const [currentItem, setCurrentItem] = useState({});
    const [formMessage, setFormMessage] = useState('');
    const [formState, setFormState] = useState(0);

    const handleSearchCriteria = async (e) => {
        setSearchCriteria(e.target.value);
    };

    //list search results whenever searchCriteria changes
    useEffect(() => {
        const getData = async () => {
            if (searchCriteria && searchCriteria.length > 12) {
                var responseItem = await VersionTwoService.faceview(searchCriteria);
                var returnedObj = { ...responseItem };
                setCurrentItem(returnedObj);
                setSearchCriteria('');
            }
        }
        getData();
    }, [searchCriteria]);

    return <>
        <section className="bg-grey-200 w-full m-2" id='content'>
            <div className="flex w-full flex-wrap mb-3 h-6 justify-items-start flex-rows-2 md:flex-rows-1 md:flex-cols-2 md:justify-between">
                <div className="flex w-full justify-center md:justify-start md:w-1/2 border-b-1 border-gray-100">
                    <h1 className="text-xl text-black justify-self-center"> Face View  </h1>
                </div>
                <div className="flex w-full justify-center md:justify-end md:w-1/2 border-b-1 border-gray-100">
                    <input placeholder="Search Consumer" tabIndex="0" type="text"
                        className="placeholder:text-gray-400 h-7 w-full bg-white rounded border border-gray-100 focus:border-sky-600 focus:ring-2 focus:ring-blue-100
                                text-base outline-none transition-colors duration-200 ease-in-out md:max-w-md md:w-60 md:place-self-end pl-2" />
                </div>
            </div>
            <div className="w-full h-full grid grid-col bg-white">
                &nbsp;
            </div>
            <div className="w-full rounded-lg grid grid-col md:grid-cols-2 gap-2" id="svg-container">
            </div>
        </section>
    </>
}
export default FaceViewComponent;
