import React, { useState, useEffect } from 'react';
import { DocumentReportIcon, ChartPieIcon, CloudIcon, CollectionIcon } from '@heroicons/react/solid'
import AdministrationService from '../../services/AdministrationService';



const DashboardStatusComponent = ({ periodEndDate, filterChannelId ,channelId }) => {
    const [dataArray, setDataArray] = useState([]);
    let statusIcon = "";

    useEffect(() => {
        const getchannelId = JSON.parse(sessionStorage.getItem('_WebsiteToken')).user.channelId;
        getData(filterChannelId === 0 ? getchannelId : filterChannelId, periodEndDate);

    }, [periodEndDate, filterChannelId]);


    const getData = async (channelId, periodEndDate) => {
        var response = await AdministrationService.getMethodCallCount(channelId, periodEndDate);
        setDataArray(response.data);
    }
    function statusIcons(apiMethod)
    {
        if (apiMethod == 'Kyc')
        {
            return statusIcon = 'DocumentReportIcon';
        }
        else if (apiMethod == 'Identity')
        {
            return statusIcon = 'CloudIcon';
        }
        else if (apiMethod == 'Contact')
        {
            return statusIcon = 'CollectionIcon';
        }
        else
        {
            return statusIcon = 'ChartPieIcon';
        }
    }




	return <>		
        <div className="w-full h-full grid grid-cols-6 gap-4 mb-4">
            {dataArray?.map((item) => {
                return <>
                    <div className="rounded bg-white">
                        <div class="flex-auto p-4">
                            <div class="flex flex-row -mx-3">
                                <div class="flex-none w-2/3 max-w-full px-3">
                                    <div>
                                        <p class="mb-0 font-sans  font-semibold leading-normal text-size-sm">Daily {item.apiMethod }</p>
                                        <hr class="border-b-1 border-grey-300" />
                                        <h5 class="mb-0 font-bold">
                                            {item.today}     &nbsp;  &nbsp;
                                        </h5>
                                    </div>
                                </div>
                                <div class="px-3 text-right basis-1/3">
                                    <div class="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500">
                                        {statusIcons(item?.apiMethod) == 'DocumentReportIcon' && <DocumentReportIcon className="h-15 w-15 text-white" />}
                                        {statusIcons(item?.apiMethod) == 'CloudIcon' && <CloudIcon className="h-15 w-15 text-white" />}
                                        {statusIcons(item?.apiMethod) == 'CollectionIcon' && <CollectionIcon className="h-15 w-15 text-white" />}
                                        {statusIcons(item?.apiMethod) == 'ChartPieIcon' && <ChartPieIcon className="h-15 w-15 text-white" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            })}
        </div>
	</>
}
export default DashboardStatusComponent;
