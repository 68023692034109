import axios from 'axios' 
import { filter } from 'd3';

class AdministrationService {
    validateChannel = async (credentials) => {
        try { 
            const response = await axios.post('api/Administration/ValidateChannel', credentials);
            return response.data;  //check response and store using checksession
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };

    loginChannel  = async (username, password) => {
        try { 
            const response = await axios.get(`api/Administration/LoginChannel?username=${username}&password=${password}`);
            return response.data;  //check response and store using checksession
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };

    getChannel = async (channelId) => {
        try {
            const response = await axios.get('api/Administration/Channel?channelId=' + channelId);
            return response.data;  //check response and store using checksession
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };

    saveChannel = async (channel) => {
        try {
            const response = await axios.post('api/Administration/Channel', channel);
          
            return response.data;  //check response and store using checksession
        }
        catch (err) {
            console.log(err);
          
            return [];
        }
    };

    getAccessList = async (channelId) => {
        try {
            const response = await axios.get('api/Administration/ChannelAccessList?channelId=' + channelId);
            return response.data;  //check response and store using checksession
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };

    checksession = async (credentials) => {
        if (credentials) {
          
            //set session storage    
            sessionStorage.setItem('_WebsiteToken', JSON.stringify(credentials));
            //sessionStorage.setItem('_WebsiteUser_LastVisitedPage', '/Home'); 
            return credentials;
        } else {
     
            const token = sessionStorage.getItem('_WebsiteToken'); 
            //const lastvisitedpage = sessionStorage.getItem('_WebsiteUser_LastVisitedPage'); 
            if (token) {
                return JSON.parse(token);
            }  else {
                return undefined; 
            }
        }
    }

    logout = () => {
        sessionStorage.removeItem('_WebsiteToken'); 
    }


    addCredentials = (idNumber) => {
     
        const token = JSON.parse(sessionStorage.getItem('_WebsiteToken'));

        //console.log(JSON.parse(token));


        return {
            "credentials": {
                "channelName": token.user.channelName,
                "channelHash": token.user.channelPassword,  //actual is the channel hash for convinience
                "timestamp":  token.user.dateCreated
            },
            "userName": "portal",
            "idNumber": idNumber,
            "foreignReference": "portal_" + token.user.channelName
        };
    }

    getUsageChart = async ({ periodEndDate, chartInterval, chartPeriod, chartGroup, top  }, channelId, filterDate) => {
        try {  
            const response = await axios.get(`api/Administration/DashboardChart?channelId=${channelId}&filterDate=${filterDate}`);
            return response.data;
        }
        catch (err) {
            console.log(err);
            return [];
        }
    }

    getMethodCallCount = async (channelId, filterDate) => {
        try {
            //var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.get(`api/Administration/DashboardHeader?channelId=${channelId}&filterDate=${filterDate}`);
            return response;
            //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };
 
    getTop5 = async (channelId, filterDate) => {
        try {
            //var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.get(`api/Administration/DashboardTop5?channelId=${channelId}&filterDate=${filterDate}`);
            return response;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };
    getRecent = async (channelId) => {
        try {
            //var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.get(`api/Administration/RecentTransactions?channelId=${channelId}`);
            return response;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };

    getMethodUsageStats = async (channelId, filterDate) => {
        try {
            //var payload = AdministrationService.addCredentials(idNumber);
            const response = await axios.get(`api/Administration/MethodUsageStats?channelId=${channelId}&filterDate=${filterDate}`);
            return response;  //return the full response object
        }
        catch (err) {
            console.log(err);
            return [];
        }
    };


    getPermissions = async () => {
        try {
            this.addSessionHeader();
            const response = await axios.get('/api/Administration/GetPermisions');

             
                // var groupedData = response.data.reduce((dataSoFar, { resource, allowed, permissionName, permissionDescription, permissionId }) => {
                var groupedData = response.data.reduce((dataSoFar, { resource, isAllowed, apiMethodName, apiMethodDescription, apiMethodId }) => {
                if (!dataSoFar[resource]) dataSoFar[resource] = { categoryName: resource, items: [] };
                    dataSoFar[resource].items.push({ isAllowed, apiMethodName, apiMethodDescription, apiMethodId });
                return dataSoFar;
            }, {});

            return Object.values(groupedData);
        }
        catch (err) {
            console.log(err);
            return [];
        }
    }

    getReportUrl = () =>
    {
        return 'http://localhost:83/default.aspx?';
    }

}

export default new AdministrationService();