import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment';
import AdministrationService from '../services/AdministrationService';

const ChannelsComponent = () => {
   
    const [currentList, setCurrentList] = useState([]);
    const [currentItem, setCurrentItem] = useState({});
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');


    useEffect(() => {
        getListData(0);
    }, []);



    const getListData = async (channelId) => {
        var responseItem = await AdministrationService.getChannel(channelId);
        setCurrentList(responseItem);
    };



    const getData = async (channelId) => {
        setStatusMessage('');
        var responseItem = await AdministrationService.getChannel(channelId);
        var accessList = await AdministrationService.getAccessList(channelId);
        if (responseItem[0]) {
            responseItem[0].confirmPassword = responseItem[0].channelPassword;
            responseItem[0].accessList = accessList;
            setCurrentItem(responseItem[0]);
        }
    };

    const save = async () => {

        setLoading(true);
        var responseItem = await AdministrationService.saveChannel({ ...currentItem });
        if (responseItem && responseItem.relatedId > 0) getData(responseItem.relatedId);
        getListData(0);

        setStatusMessage('Success');
        setLoading(false)
    };

    const addItem = async () => {
        setStatusMessage('');
        var responseItem = await AdministrationService.getAccessList(0);
        setCurrentItem({
            channelId: 0,
            channelName: '',
            channelPassword: '',
            confirmPassword: '',
            isActive: true,
            dateCreated: new Date(),
            accessList: responseItem
        });
    };

    const handleChange = (e) => {
        setStatusMessage('');
        const name = e.target.name;
        var value = e.target.value

        if (name === "isActive") {
            value = JSON.parse(e.target.value);
        }

        var updatedObject = { ...currentItem, [name]: value };
        setCurrentItem(updatedObject);
    };

    const handleAclChange = (newAcl) => {
        var updatedObject = { ...currentItem };

        for (var i = 0; i < updatedObject?.accessList?.length; i++) { 
            if (updatedObject.accessList[i].apiMethodId === newAcl.apiMethodId) {
                updatedObject.accessList[i].isAllowed = !updatedObject.accessList[i].isAllowed;
                
            }
        } 
         
        setCurrentItem(updatedObject);
    };

    return <>
        <section className="bg-grey-200 w-full m-2" id='content'>
            <div className="flex w-full flex-wrap mb-3 h-6 justify-items-start flex-rows-2 md:flex-rows-1 md:flex-cols-2 md:justify-between">
                <div className="flex w-full justify-center md:justify-start md:w-1/2 border-b-1 border-gray-100">
                    <h1 className="text-xl  justify-self-center  font-semibold"> Channels  </h1>
                </div>
            </div>

            <div className="w-full h-full grid grid-cols-4 gap-2 mb-2">
                <div className="rounded bg-white  col-span-2">
                    <div class="flex-auto p-4">
                        <div class="flex flex-row -mx-3">
                            <div class="flex-none w-full px-3">
                                <div className="w-full">
                                    <div className="grid grid-cols-5 gap-3 justify-between bg-gradient-to-tr from-gray-700 to-gray-500 text-white">
                                        <div className="px-2">
                                            <span>Channel Id</span>
                                        </div>
                                        <div className="px-2">
                                            <span> Name</span>
                                        </div>

                                        <div className="px-2">
                                            <span>Is Active</span>
                                        </div>

                                        <div className="px-2">
                                            <span>Date Created</span>
                                        </div>
                                        <div className="px-2  bg-white">
                                            <button type="submit" className="w-full self-end col-span-1 hover:bg-gray-700 bg-black text-white rounded" onClick={() => addItem()}>Add</button>
                                        </div>
                                    </div>
                                    <div >
                                        {currentList?.map((channel) => {
                                            return (<>
                                                <div className="grid grid-cols-5 gap-3 border-t justify-between">

                                                    <div className="px-2">
                                                        <span>{channel?.channelId}</span>
                                                    </div>
                                                    <div className="px-2">
                                                        <span>{channel?.channelName}</span>
                                                    </div>
                                                    <div className="px-2">
                                                        <span>{channel?.isActive ? 'Yes' : 'No'}</span>
                                                    </div>
                                                    <div className="px-2">
                                                        <span>{moment(channel?.dateCreated).format("DD MMM YYYY")}</span>
                                                    </div>
                                                    <div className="px-2">
                                                        <button type="submit" className="w-full self-end col-span-1 bg-gray-500 hover:bg-black text-white rounded" onClick={() => getData(channel?.channelId)}>Edit</button>
                                                    </div>
                                                </div>
                                            </>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded bg-white  col-span-2">
                    <div class="flex-auto p-4">
                        <div class="flex flex-row -mx-3">
                            <div class="flex-none w-full px-3 ">
                                <div className="w-full">
                                    <div className="grid md:grid-cols-4 gap-2 px-2">

                                        <div className="col-span-3">
                                            {(statusMessage !== '') && (
                                                <div className="">
                                                    <div className="col-span-1 bg-green-200 text-white rounded text-center">
                                                        <span className="text-green-800 p-2 text-bold text-sm">{statusMessage}</span>
                                                    </div>
                                                </div>
                                              )
                                            }
                                        </div>

                                        {
                                            loading
                                            ?
                                            <svg className="self-end animate-spin -ml-1 mr-3 h-5 w-5 mt-1 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            :
                                            <button disabled={!currentItem?.channelName || !currentItem?.channelPassword || !currentItem?.confirmPassword} type="submit" onClick={() => save()} className="self-end col-span-1 bg-red-600 text-white rounded">Save</button>
                                        }
                                    </div>
                                    <div className="grid md:grid-cols-3 gap-2 px-2">
                                        <div className="w-full flex flex-col col-span-3 grid md:grid-cols-3 md:max-w-full gap-2">
                                            <div className="relative mb-2">
                                                <label htmlFor="channelId" className="leading-7 text-sm text-black">Channel Id</label>
                                                <input type="text" name="channelId" value={currentItem?.channelId}
                                                    className="pl-3 placeholder:text-blue-800 w-full  rounded border border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-100
 text-base outline-none text-sky-900 leading-8 transition-colors duration-200 ease-in-out" readonly />
                                            </div>
                                            <div className="relative mb-2">
                                                <label htmlFor="channelName" className="leading-7 text-sm text-black">Channel Name</label>
                                                <input value={currentItem?.channelName} type="text" name="channelName"
                                                    className="pl-3 placeholder:text-blue-800 w-full  rounded border border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-100
 text-base outline-none text-sky-900 leading-8 transition-colors duration-200 ease-in-out"   onChange={(e) => { handleChange(e); }} />
                                            </div>
                                            <div className="relative mb-2">
                                                <label htmlFor="isActive" className="leading-7 text-sm text-black">Is Active</label>
                                                <br />

                                               {currentItem?.isActive ?
                                               <div>
                                                     <div className="relative inline-flex self-center">
                                                            <svg className="text-white font-bold bg-black absolute top-0 right-0 m-1 pointer-events-none p-2 rounded" xmlns="http://www.w3.org/2000/svg" width="30px" height="25px" viewBox="0 0 38 22" version="1.1">
                                                                <title>F09B337F-81F6-41AC-8924-EC55BA135736</title>
                                                                <g id="ZahnhelferDE�Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <g id="ZahnhelferDE�Icon&amp;Asset-Download" transform="translate(-539.000000, -199.000000)" fill="#ffffff" fill-rule="nonzero">
                                                                        <g id="Icon-/-ArrowRight-Copy-2" transform="translate(538.000000, 183.521208)">
                                                                            <polygon id="Path-Copy" transform="translate(20.000000, 18.384776) rotate(135.000000) translate(-20.000000, -18.384776) " points="33 5.38477631 33 31.3847763 29 31.3847763 28.999 9.38379168 7 9.38477631 7 5.38477631"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            <select name="isActive" onChange={(e) => { handleChange(e); }} className="text-black w-full  rounded border border-gray-300 focus:border-gray-300 h-8 w-72 pl-5 pr-10 bg-white focus:outline-none appearance-none">
                                                                <option selected value={true}> Yes</option>
                                                                <option value={false}> No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    :
                                                     <div>
                                                     <div className="relative inline-flex self-center">
                                                            <svg className="text-white font-bold bg-black absolute top-0 right-0 m-1 pointer-events-none p-2 rounded" xmlns="http://www.w3.org/2000/svg" width="30px" height="25px" viewBox="0 0 38 22" version="1.1">
                                                                <title>F09B337F-81F6-41AC-8924-EC55BA135736</title>
                                                                <g id="ZahnhelferDE�Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <g id="ZahnhelferDE�Icon&amp;Asset-Download" transform="translate(-539.000000, -199.000000)" fill="#ffffff" fill-rule="nonzero">
                                                                        <g id="Icon-/-ArrowRight-Copy-2" transform="translate(538.000000, 183.521208)">
                                                                            <polygon id="Path-Copy" transform="translate(20.000000, 18.384776) rotate(135.000000) translate(-20.000000, -18.384776) " points="33 5.38477631 33 31.3847763 29 31.3847763 28.999 9.38379168 7 9.38477631 7 5.38477631"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            <select name="isActive" onChange={(e) => { handleChange(e); }} className="text-black w-full  rounded border border-gray-300 focus:border-gray-300 h-8 w-72 pl-5 pr-10 bg-white focus:outline-none appearance-none">
                                                                <option value={true}> Yes</option>
                                                                <option selected value={false}> No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                               }

                                            </div>
                                            <div className="relative mb-2">
                                                <label htmlFor="dateCreated" className="leading-7 text-sm text-black">Date Created</label>
                                                <input type="text" name="dateOfBirth" value={moment(currentItem?.dateCreated).format("DD MMM YYYY")}
                                                    className="pl-3 placeholder:text-blue-800 w-full  rounded border border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-100
 text-base outline-none text-sky-900 leading-8 transition-colors duration-200 ease-in-out" onChange={(e) => { handleChange(e); }} />
                                            </div>
                                            <div className="relative mb-2">
                                                <label htmlFor="channelPassword" className="leading-7 text-sm text-black">Password</label>
                                                <input type="password" value={currentItem?.channelPassword} name="channelPassword"
                                                    className="pl-3 placeholder:text-blue-800 w-full  rounded border border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-100
 text-base outline-none text-sky-900 leading-8 transition-colors duration-200 ease-in-out"  onChange={(e) => { handleChange(e); }} />
                                            </div>
                                            <div className="relative mb-2">
                                                <label htmlFor="confirmPassword" className="leading-7 text-sm text-black">confirmPassword</label>
                                                <input type="password" value={currentItem?.confirmPassword} name="confirmPassword"
                                                    className="pl-3 placeholder:text-blue-800 w-full  rounded border border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-100
 text-base outline-none text-sky-900 leading-8 transition-colors duration-200 ease-in-out"   onChange={(e) => { handleChange(e); }} />
                                            </div>
                                        </div>
                                        <div className="col-span-3 border border-gray-100">
                                            <div className="grid grid-cols-3 gap-8 justify-between  bg-gradient-to-tr from-gray-700 to-gray-500 text-white">
                                                <div className="px-2">
                                                    <span>Resource</span>
                                                </div>
                                                <div className="px-2">
                                                    <span>Method Name</span>
                                                </div>
                                                <div className="px-2">
                                                    <span>Allowed</span>
                                                </div> 
                                            </div>
                                        </div>
                                        <div className="col-span-3">
                                            {currentItem?.accessList?.map((acl) => {
                                                return (<>
                                                    <div className="grid grid-cols-3 gap-8 border-t justify-between">
                                                        <div className="px-2">
                                                            <span>{acl?.resource}</span>
                                                        </div>
                                                        <div className="px-2">
                                                            <span>{acl?.apiMethodName}</span>
                                                        </div> 
                                                        <div className="px-2">
                                                            <span>
                                                            {/*    <button onClick={() => { handleAclChange(acl) }}>{acl?.isAllowed ? 'Yes' : 'No'}</button>*/}
                                                            {/*</span>*/}


                                                            <button onClick={() => { handleAclChange(acl) }} className={acl?.isAllowed ? "bg-green-600 hover:bg-green-200 text-white font-bold  px-4 rounded-full" : "bg-red-600 hover:bg-red-200 text-white font-bold px-4 rounded-full" }>
                                                                {acl?.isAllowed ? 'Yes' : 'No'}
                                                                </button>
                                                                </span>

                                                        </div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
}
export default ChannelsComponent;
