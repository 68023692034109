import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AdministrationService from '../services/AdministrationService';

const NavMenu = ({ isLoggedIn, setIsLoggedIn, menus, setMenus }) => {
    const history = useHistory();
    const [showsubmenu, setShowsubmenu] = useState(false);

    function NavNormalSubMenu() {
        /*this component represents NavMenu for large screens*/
        return <>
            <div className="font-mono text-lg grid h-10 md:inline">
                {
                    menus?.map((menu) =>
                        <Link key={menu.apiMethodId} className="font-sans text-xl cursor-pointer hover:border-b-4 hover:border-gray-500 ml-5 rounded-sm  sm:mt-2 focus:ring-0 transition duration-150 ease-in-out" onClick={() => setShowsubmenu(false)} to={menu.apiMethodName}>{menu.apiMethodDescription}</Link>
                        )   
                }
                {isLoggedIn && <> 
                    <button className="font-sans text-xl mr-2 ml-3 text-center text-white bg-black border-0 py-1 px-2 focus:outline-none hover:bg-gray-500 rounded"> <Link className=" mt-4 text-white"
                        onClick={logoutSession} to="/Login">Logout</Link> </button>
                </>


                }
            </div>
        </>
    }

    function NavMobileSubMenu() {
        /*this component represents NavMenu for small screens*/
        return <>
            <div className="dark absolute top-30 left-0 z-20 flex flex-col font-semibold w-full bg-white shadow-md rounded-lg md:bg-transparent px-4">
                {isLoggedIn && <>
                    <span className="grid dark:bg-gray-500 w-full h-30 mb-4">
                        {isLoggedIn && <hr /> && <Link className=" mt-2 text-green-500"
                            onClick={logoutSession} to='/'>Logout</Link>} <hr />
                        {
                        menus?.map((menu) => 
                            <Link key={menu.apiMethodId} className=" mt-2" onClick={() => setShowsubmenu(false)} to={menu.apiMethodName}>{menu.apiMethodDescription}</Link>
                            ) 
                        }
                    </span>

                </>
                }

            </div>
        </>
    }

    const logoutSession = () => {
        AdministrationService.logout();
        setIsLoggedIn(false);
        //redirect     
        history.push('/');
        setMenus([]);
    };

    return <>
        <header className="relative bg-white md:flex md:flex-row md:justify-between md:flex-wrap md:items-center md:space-x-2 border-b border-gray-300">
            <a className="items-start w-40 h-auto ml-3" href="/Home">
                <img src="helium-3-logo.png" className="inline border-none" alt="Hibred" />
            </a>
            <button onClick={() => setShowsubmenu(!showsubmenu)} className="absolute top-5 right-12 w-32 h-16 rounded sm:visible md:invisible">
                
                <svg xmlns="http://www.w3.org/2000/svg" className="ml-10 w-14 h-14 inline align-end visible shadow-sm md:h-0 md:w-0 md:invisible" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h7" />
                </svg>
            </button>
            <div className="visible justify-end md:invisible md:h-0 md:w-0">
                {
                    showsubmenu ?
                        <>
                            <nav className="absolute top-20 flex flex-col font-semibold w-full bg-white shadow-md rounded-lg p-6 pt-0 ">
                                {/* <></>*/}
                                <>{!isLoggedIn && <Link className="ml-5  sm:mt-2 text-green-500" to="/Login">Login</Link>}</>
                                <NavMobileSubMenu />

                            </nav>
                        </>
                        : <></>
                }
            </div>
            <div className="hidden md:flex md:justify-end">
                <nav className="bottom-2 absolute grid-rows-2 mr-4">
                    <div className="mb-2 md:row-span-1 md:flex md:justify-end">
                        
                        <>{!isLoggedIn && <Link className="ml-5  mt-2 text-black hover:text-gray-500" to="/Login">Login</Link>}</>
                    </div>
                    <NavNormalSubMenu />
                </nav>
            </div>
        </header>
    </>
}

export default NavMenu;